import React, { useState } from 'react';
import bus from '../assets/loading/bus.png';
import loading_bus from '../assets/videos/loading/loading_bus_v2.webm';
import muteIcon from '../assets/icons/mute.png';
import unmuteIcon from '../assets/icons/unmute.png';

const Loader = ({ onClick }) => {
  const [isMuted, setIsMuted] = useState(true);

  const handleMuteToggle = () => {
    setIsMuted(!isMuted);
  };

  return (
    <div className="fixed inset-0 flex justify-center items-end bg-black z-50">
      <video
        src={loading_bus}
        autoPlay
        loop
        muted={isMuted}
        playsInline
        className="absolute inset-0 w-full h-full object-cover"
        type="video/webm"
      />
      <img
        src={bus}
        alt="Start"
        className="px-8 mb-44 sm:mb-48 w-[50rem] z-50 cursor-pointer heartbeat"
        onClick={onClick}
      />
      <button
        onClick={handleMuteToggle}
        className="absolute top-4 right-4 z-50"
      >
        <img
          src={isMuted ? muteIcon : unmuteIcon}
          alt={isMuted ? "Unmute" : "Mute"}
          className={`w-12 h-12 sm:w-24 sm:h-24 mt-8 ${isMuted ? 'heartbeat' : ''}`}
        />
      </button>
    </div>
  );
};

export default Loader;