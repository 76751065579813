import React, { useState } from 'react';
import About from "../components/About";
import Atonomics from "../components/Atonomics";
import Header from "../components/Header";
import HowToBuy from "../components/HowToBuy";
import Footer from "../components/Footer";
import Loader from "../components/Loader";

const Home = () => {
  const [loading, setLoading] = useState(true);

  const handleLoaderClick = () => {
    setLoading(false);
  };

  return (
    <div>
      {loading && <Loader onClick={handleLoaderClick} />}
      {!loading && (
        <>
          <Header />
          <About />
          <HowToBuy />
          <Atonomics />
          <Footer />
        </>
      )}
    </div>
  );
};

export default Home;